import {
  ContentSummary as CommonContentSummary,
  ContentSummaryProps,
} from "common/components/ContentSummary"
import { ContentSummaryBlock } from "common/types/graphql"

export type ContentSummaryFrontendBlock = ContentSummaryProps & Omit<ContentSummaryBlock, "image">

export const ContentSummary = (props: ContentSummaryFrontendBlock) => {
  return <CommonContentSummary {...props} />
}
